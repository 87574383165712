/* Expose global vars in root, no need to import these when used */
:root {
  /* colors */

  /* fonts */

  /* animation durations */

  /* Sizes */
}
/* breakpoints
  usage:
  @import path/to/settings.css;
  .className {
    @media (--bp2) {
      ...styles
    }
  }
*/
html {
  font-size: 62.5%; /* 1rem = 10px */
  height: 100%;
}
body {
  background: black;
  color: white;
  font-family: 'DAZN Oscine', Helvetica, Arial, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  min-height: 100%;
}
body.builder {
    background: #333;
  }
body.no-scroll {
  overflow: hidden;
}
a {
  color: white;
}
/* If it's a link inside a white themed component make the text black */
div[class*="__white"] a {
  color: black;
}
p {
  font-size: 1.6rem;
}
* {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
pre {
  background-color: rgba(255, 255, 255, 0.4);
  font-size: 18px;
  margin: 8px;
  padding: 8px;
}
.button-as-link {
  -webkit-transition: .2s ease all;
  transition: .2s ease all;
  background: none !important;
  color: inherit;
  border: none;
  padding: 0 !important;
  font: inherit;
  text-decoration: none;
  border-bottom: 1px solid white;
  border-radius: 0;
}
.clearfix::after {
    clear: both;
    content: '.';
    display: block;
    height: 0;
    visibility: hidden;
  }
.button-reset {
  display: block;
  font-size: 1rem;
  border: 0;
  background: none;
  outline: 0;
  padding: 0;
  margin: 0;
}
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
.capitalize {
  text-transform: capitalize;
}
html {
  font-size: 62.5%;
}
body {
  color: white;
  font-family: sans-serif;
  margin: 0;
  padding: 0;
}
body.no-scroll {
    overflow: hidden;
  }
