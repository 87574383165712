/* Expose global vars in root, no need to import these when used */
:root {
  /* colors */

  /* fonts */

  /* animation durations */

  /* Sizes */
}
/* breakpoints
  usage:
  @import path/to/settings.css;
  .className {
    @media (--bp2) {
      ...styles
    }
  }
*/
.reports-cards-list__list {
    padding: 3.2rem 0;
    max-width: 96rem;
    margin: 0 auto;
    list-style: none;
  }
@media (min-width: 760px) {
.reports-cards-list__list {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-flow: row wrap;
              flex-flow: row wrap
  }
    }
.reports-cards-list-loading,
  .reports-cards-list-no-results {
    font-size: 2rem;
    text-align: center;
    margin: 3rem 0;
    color: #ccc;
  }
