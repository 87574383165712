/* Expose global vars in root, no need to import these when used */
:root {
  /* colors */

  /* fonts */

  /* animation durations */

  /* Sizes */
}
/* breakpoints
  usage:
  @import path/to/settings.css;
  .className {
    @media (--bp2) {
      ...styles
    }
  }
*/
.navigation {
  padding: 0 1.6rem;
  text-align: right;
  background: #2b2b2b;
}
.navigation__link {
    display: inline-block;
    font-weight: bold;
    color: #ccc;
    font-family: 'DAZN Oscine', Helvetica, Arial, sans-serif;
    font-size: 1.4rem;
    padding: .8rem 2.4rem;
    text-decoration: none;
    text-transform: uppercase;
    border-bottom: 1px solid transparent;
    -webkit-transition: .2s ease all;
    transition: .2s ease all;
  }
.navigation__link__icon {
      width: 1.6rem;
      height: 1.6rem;
      margin: 0 .6rem 0 0;
      vertical-align: middle;
    }
.navigation__link__icon path,
      .navigation__link__icon rect {
        fill: #ccc;
      }
.navigation__link__name {
      vertical-align: middle;
    }
.navigation__link:hover,
    .navigation__link:focus,
    .navigation__link:active,
    .navigation__link--active {
      color: white;
      border-bottom: 1px solid white;
    }
.navigation__link--active .navigation__link__icon path {
        fill: white;
      }
