/* Expose global vars in root, no need to import these when used */
:root {
  /* colors */

  /* fonts */

  /* animation durations */

  /* Sizes */
}
/* breakpoints
  usage:
  @import path/to/settings.css;
  .className {
    @media (--bp2) {
      ...styles
    }
  }
*/
.locale-defaults-page {
  padding: 3.2rem;
}
.locale-defaults-page__controls {
    margin: 2.4rem 0;
    text-align: center;
  }
.locale-defaults-page__loading {
    font-size: 2rem;
  }

