/* Expose global vars in root, no need to import these when used */
:root {
  /* colors */

  /* fonts */

  /* animation durations */

  /* Sizes */
}
/* breakpoints
  usage:
  @import path/to/settings.css;
  .className {
    @media (--bp2) {
      ...styles
    }
  }
*/
.metatag-inputs__label {
    display: block;
    font-family: 'DAZN Oscine', Helvetica, Arial, sans-serif;
    color: #ccc;
    font-size: 1.6rem;
    padding: 1.6rem 0 0;
    margin: 0 0 1.6rem;
    text-transform: initial;
  }
.metatag-inputs__label--radio, .metatag-inputs__label--checkbox {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: reverse;
          -ms-flex-direction: row-reverse;
              flex-direction: row-reverse;
      -webkit-box-pack: end;
          -ms-flex-pack: end;
              justify-content: flex-end;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
    }
.metatag-inputs__label--radio {
      display: -webkit-inline-box;
      display: -ms-inline-flexbox;
      display: inline-flex;
      margin-right: 3.2rem;
    }
.metatag-inputs__input {
    display: block;
    width: 100%;
    padding: 1.2rem 1.6rem;
    margin: .8rem 0;
    outline: 0;

    font-family: 'DAZN Oscine', Helvetica, Arial, sans-serif;
    font-size: 1.6rem;
    color: white;

    border: 0;
    border-bottom: 1px solid #333;

    background: #333;
    border-radius: .2rem;
    -webkit-transition: .3s ease all;
    transition: .3s ease all;
  }
.metatag-inputs__input--checkbox, .metatag-inputs__input--radio {
      width: auto;
      margin-right: 1.6rem;
    }
