/* Expose global vars in root, no need to import these when used */
:root {
  /* colors */

  /* fonts */

  /* animation durations */

  /* Sizes */
}
/* breakpoints
  usage:
  @import path/to/settings.css;
  .className {
    @media (--bp2) {
      ...styles
    }
  }
*/
.pills-bar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.pills-bar .pill {
    margin: 0;
    padding: 0;
    cursor: pointer;
  }
.pills-bar .pill:first-child span {
      border-top-left-radius: .2rem;
      border-bottom-left-radius: .2rem;
    }
.pills-bar .pill:last-child span {
      border-top-right-radius: .2rem;
      border-bottom-right-radius: .2rem;
    }
.pills-bar .pill__content {
      text-align: center;
      font-family: 'DAZN Oscine', Helvetica, Arial, sans-serif;
      color: #ccc;
      background-color: #252122;
      font-weight: bold;
      font-size: 1.4rem;
      padding: .5rem 1.2rem;
      -webkit-transition: .3s ease all;
      transition: .3s ease all;
      display: block;
    }
.pills-bar .pill__input {
      position: absolute;
      top: -2rem;
    }
.pills-bar .pill svg {
      width: 3.6rem;
      height: 3.2rem;
      fill: #ccc;
    }
.pills-bar .pill__input:checked + * {
      background-color: #f9b13b;
      color: white;
      fill: #252122;
    }
