/* Expose global vars in root, no need to import these when used */
:root {
  /* colors */

  /* fonts */

  /* animation durations */

  /* Sizes */
}
/* breakpoints
  usage:
  @import path/to/settings.css;
  .className {
    @media (--bp2) {
      ...styles
    }
  }
*/
.button {
  border: 1px solid white;
  border-radius: .2rem;
  background: transparent;
  -webkit-box-shadow: 1px 1px 1px rgba(0, 0, 0, .25);
          box-shadow: 1px 1px 1px rgba(0, 0, 0, .25);

  display: inline-block;

  outline: 0;
  padding: .8rem 3.2rem;
  margin: 0;

  font-size: 1.4rem;
  font-family: 'DAZN Oscine', Helvetica, Arial, sans-serif;
  font-weight: bold;
  text-decoration: none;
  text-transform: uppercase;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, .25);
  color: white;

  cursor: pointer;
  -webkit-transition: .3s ease all;
  transition: .3s ease all;
}
.button-icon {
    width: 1.2rem;
    height: 1.2rem;
    margin: 0 .4rem 0 0;
    vertical-align: middle;
    -webkit-filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, .25));
            filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, .25));
  }
.button-icon path {
      fill: white;
    }
.button-label {
    line-height: 1;
  }
.button--warning {
    background: #ea3757;
  }
.button--action {
    background: #f9b13b;
  }
.button--warning, .button--action {
    border-color: transparent;
  }
.button:disabled,
  .button--disabled {
    opacity: .5;
    cursor: not-allowed;
  }
.button--inline {
    margin: 0 .8rem 0 0 !important;
  }
.button--full-width {
    width: 100%;
  }
.button--tight-padding {
    padding: .8rem;
  }
.button--margin-right {
    margin-right: 1rem;
  }
.button > span {
    vertical-align: middle;
  }
.builder-modal__form {
    text-align: center;
  }
.builder-modal__form-field {
      display: block;
      padding: 1.2rem 1.6rem;
      margin: .8rem 1.6rem 1.6rem 0;
      width: 100%;
      outline: 0;
      border: 0;
      background: #484848;
  
      font-family: 'DAZN Oscine', Helvetica, Arial, sans-serif;
      font-size: 1.6rem;
      color: white;
      
      -webkit-transition: .3s ease all;
      
      transition: .3s ease all;
      border-bottom: 1px solid #484848;
      border-radius: .2rem;
    }
.builder-modal__form-field--error {
        border-bottom: 1px solid #ea3757;
      }
.builder-modal__form-radio-field {
      display: inline;
      width: auto;
    }
.builder-modal__form-file {
      width: .1px;
      height: .1px;
      opacity: 0;
      overflow: hidden;
      position: absolute;
      z-index: -1;
    }
.builder-modal__form-split-buttons {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      margin-bottom: 1rem;
    }
.builder-modal__form-center-buttons {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      margin-bottom: 1rem;
    }
.builder-modal__form-label {
      display: block;
      padding: 1.6rem 0 0;
      margin: 0 0 1.6rem;
  
      color: white;
      font-family: 'DAZN Oscine', Helvetica, Arial, sans-serif;
      font-size: 1.6rem;
      font-weight: bold;
      text-align: left;
      text-transform: capitalize;
    }
.builder-modal__form-label--upper {
        text-transform: uppercase;
      }
.builder-modal__form-label--theme {
        text-transform: none;
      }
.builder-modal__form-label--file-uploader {
        padding: 0 2rem;
        text-align: center;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        position: relative;
        -webkit-box-align: end;
            -ms-flex-align: end;
                align-items: end;
      }
.builder-modal__form-label--file-uploader--list {
        padding: 0;
      }
.builder-modal__form-label--file-updater {
        text-align: center;
      }
.builder-modal__form-label--file-preview {
        height: 100%;
        display: block;
        padding: 2rem;
        background-color: #ccc;
        margin-bottom: 1rem;
        text-decoration: none;
      }
.builder-modal__form-label--file-preview-image {
        max-width: 100%;
      }
.builder-modal__form-label--file-preview-image-label {
        color: black;
        display: block;
        text-transform: initial;
      }
.builder-modal__form-label--left {
        max-width: 150px;
        height: 100%;
        margin: 0 1.6rem 1.6rem 0;
      }
.builder-modal__form-label--right {
        width: 80%;
        padding-top: 1.6rem;
        position: relative;
        z-index: 1;
      }
.builder-modal__form-label--center {
        width: 25%;
        height: 100%;
        margin: 2rem auto;
      }
.builder-modal__form-label__upload-center {
        width: 70%;
        height: 100%;
        margin: 2rem auto 0 auto;
      }
.builder-modal__form-label--right--list {
        padding-top: 0;
      }
.builder-modal__form-label--25 {
        display: inline-block;
        text-transform: initial;
        width: 25%;
        font-size: 1.4rem;
        font-family: 'DAZN Oscine', Helvetica, Arial, sans-serif;
        color: #ccc;
        outline: 0;
        border: 0;
        background: #484848;
        border-bottom: 1px solid #555;
        padding: 1.2rem 4.8rem 1.2rem 2.4rem;
        margin: 0;
        cursor: pointer;
        text-align: left;
        position: relative;
        border-radius: 0;
        border-right: 1px solid #555;
      }
.builder-modal__form-label--25 input {
          display: none;
        }
.builder-modal__form-label--25:hover {
          color: white;
          border-bottom: 1px solid #ccc;
        }
.builder-modal__form-label--active {
        color: white;
        border-bottom: 1px solid #ccc;
      }
.builder-modal__form-radio-label {
      display: inline-block;
    }
.builder-modal__form-label--list {
      padding-top: 5rem;
    }
.builder-modal__form-radio-group {
      margin-bottom: 2rem;
      text-align: left;
    }
.builder-modal__form-radio-group__fields-wrapper {
        display: inline-block;
        margin-top: -2rem;
      }
.builder-modal__form-radio-group__fields-wrapper div {
          display: inline-block;
        }
.builder-modal__form-radio-group__fields-wrapper div:not(:last-child) {
            margin-right: 2.4rem;
          }
.builder-modal__form-theme-group {
      margin-bottom: 2rem;
      text-align: left;
    }
.builder-modal__form-theme-group__fields-wrapper {
        display: inline-block;
        margin-top: -2rem;
      }
.builder-modal__form-theme-group__fields-wrapper div {
          display: inline-block;
          margin-right: 2.4rem;
        }
.builder-modal__form-theme-group__fields-wrapper div:last-child {
          margin-right: 0;
        }
.builder-modal__form-territory {
      padding-bottom: 2.4rem;
    }
.builder-modal__form-territory-title {
      padding-left: 1.2rem;
    }
.builder-modal__image-list-field {
    display: inline-block;
    margin-right: 1rem;
    width: 70%;
  }
.builder-modal textarea {
    min-height: 4.4rem;
    resize: vertical;
    -webkit-transition: none;
    transition: none;
  }
.image-list,
.faq-list,
.schedule-list,
.footer-list,
.about-subscription-list,
.carousel-list,
.subscription-plans-list,
.dynamic-schedule-list,
.multiple-cta-plans-list,
.base-list {
  margin-bottom: 4.8rem;
}
.image-list__heading, .faq-list__heading, .schedule-list__heading, .footer-list__heading, .about-subscription-list__heading, .carousel-list__heading, .subscription-plans-list__heading, .dynamic-schedule-list__heading, .multiple-cta-plans-list__heading, .base-list__heading {
    text-transform: capitalize;
    font-family: 'DAZN Oscine', Helvetica, Arial, sans-serif;
    font-size: 2.4rem;
    padding: 0;
    margin: 4.8rem 0 1.6rem;
  }
.image-list__list, .faq-list__list, .schedule-list__list, .footer-list__list, .about-subscription-list__list, .carousel-list__list, .subscription-plans-list__list, .dynamic-schedule-list__list, .multiple-cta-plans-list__list, .base-list__list {
    margin: 0;
    padding: 0;
  }
.image-list__item, .faq-list__item, .schedule-list__item, .footer-list__item, .about-subscription-list__item, .carousel-list__item, .subscription-plans-list__item, .dynamic-schedule-list__item, .multiple-cta-plans-list__item, .base-list__item {
    position: relative;
    list-style: none;
    margin-bottom: 4.8rem;
    background-color: #3a3a3a;
    border-radius: .2rem;
    border: 1px solid #444;
  }
.image-list__item-order, .faq-list__item-order, .schedule-list__item-order, .footer-list__item-order, .about-subscription-list__item-order, .carousel-list__item-order, .subscription-plans-list__item-order, .dynamic-schedule-list__item-order, .multiple-cta-plans-list__item-order, .base-list__item-order {
      padding: 2rem 2rem 0;
      z-index: 2;
    }
.image-list__fields, .faq-list__fields, .schedule-list__fields, .footer-list__fields, .about-subscription-list__fields, .carousel-list__fields, .subscription-plans-list__fields, .dynamic-schedule-list__fields, .multiple-cta-plans-list__fields, .base-list__fields {
    padding: 2rem;
  }
