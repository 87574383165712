.lib-selector__components {
    list-style-type: none;
    margin: 0;
    padding: 2rem;
    text-align: center;
  }
  .lib-selector__component {
    display: inline-block;
    margin: 0 .5rem 1rem;
  }
  .lib-selector__button {
    min-width: 30rem;
  }
