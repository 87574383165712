/* Expose global vars in root, no need to import these when used */
:root {
  /* colors */

  /* fonts */

  /* animation durations */

  /* Sizes */
}
/* breakpoints
  usage:
  @import path/to/settings.css;
  .className {
    @media (--bp2) {
      ...styles
    }
  }
*/
.tab-navigation {
  padding: 0 3.2rem;
  margin: 0 -3.2rem 4rem;
  list-style: none;
  border-bottom: .1rem solid #1f1f1f;
}
.tab-navigation__tab {
    display: inline-block;
    text-transform: uppercase;
  }
.tab-navigation__link {
    display: inline-block;
    text-decoration: none;
    padding: 1.6rem 2.4rem;
    font-family: 'DAZN Oscine', Helvetica, Arial, sans-serif;
    font-weight: bold;
    font-size: 1.4rem;
    border-bottom: .1rem solid transparent;
    margin-bottom: -.1rem;
    color: #ccc;
    -webkit-transition: all .25s ease-in;
    transition: all .25s ease-in;
  }
.tab-navigation__link--active {
      background: #252122;
      border-bottom-color: #f9b13b;
      color: white;
      pointer-events: none;
    }
