/* Expose global vars in root, no need to import these when used */
:root {
  /* colors */

  /* fonts */

  /* animation durations */

  /* Sizes */
}
/* breakpoints
  usage:
  @import path/to/settings.css;
  .className {
    @media (--bp2) {
      ...styles
    }
  }
*/
.asset-picker {
  text-align: left;
}
.asset-picker__list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    min-height: 40rem;
    margin: 2rem 1rem;
  }
.asset-picker__list-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    background-color: #252122;
    position: relative;
    width: 48%;
    height: 20rem;
    margin: 1%;
    padding: 1.5rem;
    overflow: hidden;
  }
@media (min-width: 1200px) {
.asset-picker__list-item {
      width: 23%
  }
    }
.asset-picker__list-image-container {
    -ms-flex-preferred-size: 72%;
        flex-basis: 72%;
    width: 100%;
    position: relative;
  }
.asset-picker__list-image-container--image {
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
      max-height: 100%;
      max-width: 100%;
    }
.asset-picker__list-label {
    -ms-flex-preferred-size: 28%;
        flex-basis: 28%;
    text-transform: initial;
    max-width: 100%;
    overflow-wrap: break-word;
    margin-top: .5rem;
  }
.asset-picker__use {
    background-color: rgba(204, 204, 204, 0.9);
    border: none;
    cursor: pointer;
    font-size: 20px;
    height: 50px;
    left: calc(50% - 50px);
    padding-top: 4px;
    position: absolute;
    text-align: center;
    text-transform: uppercase;
    top: calc(50% - 25px);
    width: 100px;
    z-index: 20;
  }
.asset-picker__asset-type-button {
    opacity: .5;
    text-transform: capitalize;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
  }
.asset-picker__asset-type-button--selected {
      opacity: 1;
    }
