/* Expose global vars in root, no need to import these when used */
:root {
  /* colors */

  /* fonts */

  /* animation durations */

  /* Sizes */
}
/* breakpoints
  usage:
  @import path/to/settings.css;
  .className {
    @media (--bp2) {
      ...styles
    }
  }
*/
.button {
  border: 1px solid white;
  border-radius: .2rem;
  background: transparent;
  -webkit-box-shadow: 1px 1px 1px rgba(0, 0, 0, .25);
          box-shadow: 1px 1px 1px rgba(0, 0, 0, .25);

  display: inline-block;

  outline: 0;
  padding: .8rem 3.2rem;
  margin: 0;

  font-size: 1.4rem;
  font-family: 'DAZN Oscine', Helvetica, Arial, sans-serif;
  font-weight: bold;
  text-decoration: none;
  text-transform: uppercase;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, .25);
  color: white;

  cursor: pointer;
  -webkit-transition: .3s ease all;
  transition: .3s ease all;
}
.button-icon {
    width: 1.2rem;
    height: 1.2rem;
    margin: 0 .4rem 0 0;
    vertical-align: middle;
    -webkit-filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, .25));
            filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, .25));
  }
.button-icon path {
      fill: white;
    }
.button-label {
    line-height: 1;
  }
.button--warning {
    background: #ea3757;
  }
.button--action {
    background: #f9b13b;
  }
.button--warning, .button--action {
    border-color: transparent;
  }
.button:disabled,
  .button--disabled {
    opacity: .5;
    cursor: not-allowed;
  }
.button--inline {
    margin: 0 .8rem 0 0 !important;
  }
.button--full-width {
    width: 100%;
  }
.button--tight-padding {
    padding: .8rem;
  }
.button--margin-right {
    margin-right: 1rem;
  }
.button > span {
    vertical-align: middle;
  }
.reports-card {
  display: block;
  background: #1f1f1f;
  margin: 0 0 1.2rem;
  padding: .8rem;
}
.reports-card__name {
    text-align: center;
    font-family: 'DAZN Oscine', Helvetica, Arial, sans-serif;
    font-size: 1.6rem;
    margin: 2.4rem 0;
  }
.reports-card__usage {
    height: .1rem;
    margin: -.8rem -.8rem 0;
    background: #7f7f7f;
  }
.reports-card__usage div {
      height: .1rem;
      background: #f9b13b;
    }
.reports-card__item {
    padding: 0 1.2rem;
    font-family: 'DAZN Oscine', Helvetica, Arial, sans-serif;
    font-size: 1.4rem;
    margin: .8rem 0;
    font-weight: bold;
    color: #ccc;
  }
.reports-card__item--highlight {
      font-size: 1.6rem;
      color: white;
    }
.reports-card__actions {
    padding: 1.2rem 0;
  }
.reports-card__actions button {
      font-size: 1.4rem;
      padding: .4rem 1.2rem;
      margin: 0 auto;
      display: block;
    }
.reports-card__actions button:not(:last-child) {
      margin-bottom: .4rem;
    }
.reports-card__no-used {
    background: rgba(37, 33, 34, 0.5);
  }
.reports-card__no-used h3,
    .reports-card__no-used p {
      color: #969696;
    }
.reports-card__no-used h3 span, .reports-card__no-used p span {
        color: #969696;
      }
.reports-card__no-used button:disabled {
      opacity: .5;
      cursor: not-allowed;
    }
@media (min-width: 760px) {
.reports-card {
    display: inline-block;
    -webkit-box-flex: 1;
        -ms-flex: 1 1 calc(33.33% - 1.2rem);
            flex: 1 1 calc(33.33% - 1.2rem);
    margin: .6rem;
    max-width: calc(33.33% - 1.2rem)
}
  }
