/* Expose global vars in root, no need to import these when used */
:root {
  /* colors */

  /* fonts */

  /* animation durations */

  /* Sizes */
}
/* breakpoints
  usage:
  @import path/to/settings.css;
  .className {
    @media (--bp2) {
      ...styles
    }
  }
*/
@media (min-width: 760px) {
.reports-filter-panel__top-panel {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center
  }
    }
.reports-filter-panel__filtering,
  .reports-filter-panel__sorting,
  .reports-filter-panel__ordering {
    margin-bottom: 1.6rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
@media (min-width: 760px) {
.reports-filter-panel__filtering,
  .reports-filter-panel__sorting,
  .reports-filter-panel__ordering {
      margin-bottom: 0
  }
    }
.reports-filter-panel__filter-text {
    padding: .5rem 1.6rem;
    margin: 0 .4rem 0 0;
    outline: 0;
    font-size: 1.4rem;
    border: 0;
    font-family: 'DAZN Oscine', Helvetica, Arial, sans-serif;
    color: white;
    background: rgba(127, 127, 127, 0.3);
    border-radius: .2rem;
  }
@media (min-width: 760px) {
.reports-filter-panel__filter-text {
      margin: 0
  }
    }
.reports-filter-panel__pills-label {
    font-family: 'DAZN Oscine', Helvetica, Arial, sans-serif;
    color: #ccc;
    font-weight: bold;
    display: inline-block;
    font-size: 1.4rem;
    margin: 0 1.2rem 0 2.4rem;
  }
