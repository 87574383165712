/* Expose global vars in root, no need to import these when used */
:root {
  /* colors */

  /* fonts */

  /* animation durations */

  /* Sizes */
}
/* breakpoints
  usage:
  @import path/to/settings.css;
  .className {
    @media (--bp2) {
      ...styles
    }
  }
*/
.button {
  border: 1px solid white;
  border-radius: .2rem;
  background: transparent;
  -webkit-box-shadow: 1px 1px 1px rgba(0, 0, 0, .25);
          box-shadow: 1px 1px 1px rgba(0, 0, 0, .25);

  display: inline-block;

  outline: 0;
  padding: .8rem 3.2rem;
  margin: 0;

  font-size: 1.4rem;
  font-family: 'DAZN Oscine', Helvetica, Arial, sans-serif;
  font-weight: bold;
  text-decoration: none;
  text-transform: uppercase;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, .25);
  color: white;

  cursor: pointer;
  -webkit-transition: .3s ease all;
  transition: .3s ease all;
}
.button-icon {
    width: 1.2rem;
    height: 1.2rem;
    margin: 0 .4rem 0 0;
    vertical-align: middle;
    -webkit-filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, .25));
            filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, .25));
  }
.button-icon path {
      fill: white;
    }
.button-label {
    line-height: 1;
  }
.button--warning {
    background: #ea3757;
  }
.button--action {
    background: #f9b13b;
  }
.button--warning, .button--action {
    border-color: transparent;
  }
.button:disabled,
  .button--disabled {
    opacity: .5;
    cursor: not-allowed;
  }
.button--inline {
    margin: 0 .8rem 0 0 !important;
  }
.button--full-width {
    width: 100%;
  }
.button--tight-padding {
    padding: .8rem;
  }
.button--margin-right {
    margin-right: 1rem;
  }
.button > span {
    vertical-align: middle;
  }
.modal {
  display: none;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.modal__form-asset-input {
    background: #484848;
    padding: 1.6rem;
    margin: 0 0 1.6rem;
  }
.modal__file-name {
    width: 100%;
    display: block;
    padding: 1.6rem;
    margin: 1.6rem 1.6rem 1.6rem 0;
    outline: 0;
    font-size: 1.6rem;
    border: 0;
    color: #ccc;
    background: none;
    text-align: center;
    border-top: 1px solid #484848;
  }
.modal__controls-cancel {
    margin: 0 .8rem 0 0 !important;
  }
.modal--showing {
    display: block;
  }
.modal__close {
    position: absolute;
    background: url('/images/icons/close.svg');
    background-repeat: no-repeat;

    height: 2.4rem;
    width: 2.4rem;
    
    right: 2.4rem;
    top: 2.4rem;

    border: none;
    padding: 0;

    cursor: pointer;
    z-index: 100;
  }
.modal__container {
    background-color: #333;
    border-radius: 0.2rem;

    position: fixed;

    width: 100%;
    max-width: 78rem;
    padding: 2.4rem;

    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);

    z-index: 30;
    overflow-y: scroll;
    max-height: 68rem;
  }
.modal__container--small {
      max-width: 54rem;
    }
.modal__container--big {
      height: 100%;
      max-width: 96rem;
    }
.modal__title {
    font-family: 'DAZN Oscine', Helvetica, Arial, sans-serif;
    font-size: 3.6rem;
    padding: 2rem 2rem 0;
  }
.modal__subtitle {
    font-family: 'DAZN Oscine', Helvetica, Arial, sans-serif;
    padding: 0 2rem;
  }
.modal__body {
    text-align: center;
    font-family: 'DAZN Oscine', Helvetica, Arial, sans-serif;
  }
.modal__body--locale {
      text-transform: capitalize;
    }
.modal__select-label {
    display: block;
    margin: 1rem 0;
    width: 100%;
    text-align: left;
  }
.modal__select {
    min-width: 48rem;
    display: block;
    font-size: 1.6rem;
    font-family: 'DAZN Oscine', Helvetica, Arial, sans-serif;
    color: #ccc;
    outline: 0;
    border: 0;
    background: #484848;
    padding: 0 .8rem;
    margin: 0;
    cursor: pointer;
    border-radius: .2rem;
    text-align: left;
    position: relative;
    z-index: 2;
    -webkit-transition: .2s ease all;
    transition: .2s ease all;
    margin-top: .8rem;
  }
.modal__select select {
      background: transparent;
      border: none;
      color: white;
      font-size: 1.4rem;
      height: 5.1rem;
      width: 100%;
      padding: 1.6rem;
    }
