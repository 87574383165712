/* Expose global vars in root, no need to import these when used */
:root {
  /* colors */

  /* fonts */

  /* animation durations */

  /* Sizes */
}
/* breakpoints
  usage:
  @import path/to/settings.css;
  .className {
    @media (--bp2) {
      ...styles
    }
  }
*/
.components-details__back-link.button.button--action {
    font-size: 1.4rem;
    padding: .4rem 1.2rem;
    margin: 0 .4rem 1.6rem 0;
  }
.components-details__subtitle span {
      color: #f9b13b;
      font-size: 1.6rem;
      display: inline-block;
      padding-left: .8rem;
      font-family: 'DAZN Oscine', Helvetica, Arial, sans-serif;
    }
.components-details__button-wrapper {
    display: inline-block;
    position: relative;
  }
.components-details--button-with-loader {
    position: relative;
  }
.components-details__button-loader {
    position: absolute;
  }
.components-details__table-action.button.button--action {
    font-size: 1.4rem;
    padding: .4rem 1.2rem;
    margin: 0 .4rem 0 0;
    text-decoration: none;
    color: white;
  }
.components-details__table-action.button.button--action:hover,
    .components-details__table-action.button.button--action:active,
    .components-details__table-action.button.button--action:focus {
      text-decoration: none;
      border: none;
    }
.components-details__site-table {
    max-width: 96rem;
    background: none;
    font-family: 'DAZN Oscine', Helvetica, Arial, sans-serif;
    font-size: 1.4rem;
    color: #ccc;
    margin: 3.6rem auto 0;
    border: 0;
  }
.components-details__site-table .rt-noData {
      background: #333;
      color: #ccc;
      font-size: 1.6rem;
      font-family: 'DAZN Oscine', Helvetica, Arial, sans-serif;
    }
.components-details__site-table__filter-text {
      width: 100%;
      padding: 1.6rem;
      margin: 0 1.6rem 1.6rem 0;
      outline: 0;
      font-size: 1.6rem;
      border: 0;
      font-family: 'DAZN Oscine', Helvetica, Arial, sans-serif;
      color: white;
      background: rgba(127, 127, 127, 0.3) !important;
      border-radius: .2rem;
    }
.components-details__site-table .-pagination .-btn {
        color: #ccc !important;
      }
.components-details__site-table a {
      color: #ccc;
      text-decoration: none;
      padding-bottom: .1rem;
      -webkit-transition: .2s ease all;
      transition: .2s ease all;
    }
.components-details__site-table a:hover,
      .components-details__site-table a:focus,
      .components-details__site-table a:active {
        color: white;
        border-bottom: .1rem solid white;
      }
.components-details__site-table.-striped .rt-tr.-odd {
          background: rgba(127, 127, 127, 0.3) !important;
          border-radius: .2rem;
        }
.components-details__site-table__column-header--left {
      text-align: left;
    }
.components-details__site-table--column {
      text-align: center;
    }
.components-details__site-table--column--name {
        text-align: left;
      }
.components-details__site-table .rt-tbody .rt-td {
        -ms-flex-item-align: center;
            align-self: center;
        padding: 1.6rem .8rem;
        border-right: .1rem solid #333;
      }
.components-details__site-table .rt-tbody .rt-tr-group {
        border-bottom: .1rem solid rgba(127, 127, 127, 0.3) !important;
      }
.components-details__site-table .rt-thead.-header {
      background: none;
      -webkit-box-shadow: initial;
              box-shadow: initial;
    }
.components-details__site-table .rt-thead.-header .rt-tr {
        font-family: 'DAZN Oscine', Helvetica, Arial, sans-serif;
      }
.components-details__site-table .rt-thead.-header .rt-tr .rt-th {
          padding: 1.6rem .8rem;
          border-right: .1rem solid #333;
        }
.components-details__site-table .rt-thead.-header .rt-tr .rt-th.-sort-desc {
            -webkit-box-shadow: inset 0 -.2rem 0 0 #ccc;
                    box-shadow: inset 0 -.2rem 0 0 #ccc;
          }
.components-details__site-table .rt-thead.-header .rt-tr .rt-th.-sort-asc {
            -webkit-box-shadow: inset 0 .2rem 0 0 #ccc;
                    box-shadow: inset 0 .2rem 0 0 #ccc;
          }
