/* Expose global vars in root, no need to import these when used */
:root {
  /* colors */

  /* fonts */

  /* animation durations */

  /* Sizes */
}
/* breakpoints
  usage:
  @import path/to/settings.css;
  .className {
    @media (--bp2) {
      ...styles
    }
  }
*/
.schedule-file-uploader .dropdownlist__items {
    overflow-y: auto;
    max-height: 25rem;
  }
.schedule-file-uploader__dropdown-top {
    position: relative;
    z-index: 2;
  }
.schedule-file-uploader__dropdown-bottom {
    position: relative;
    z-index: 1;
  }
.schedule-file-uploader__clickable {
    cursor: pointer;
  }
