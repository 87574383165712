/* Expose global vars in root, no need to import these when used */
:root {
  /* colors */

  /* fonts */

  /* animation durations */

  /* Sizes */
}
/* breakpoints
  usage:
  @import path/to/settings.css;
  .className {
    @media (--bp2) {
      ...styles
    }
  }
*/
.asset-picker {
  text-align: left;
}
.asset-picker__list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    min-height: 40rem;
  }
.asset-picker__list-item {
    position: relative;
    width: calc(25% - 4rem);
    background-color: #2b2b2b;
    margin: 2rem;
    padding: 2rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    height: 16rem;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
.asset-picker__list-image {
    width: auto;
    max-width: 100%;
    height: auto;
    max-height: 100%;
    display: block;
  }
.asset-picker__list-label {
    position: absolute;
    bottom: 5px;
    text-transform: initial;
    max-width: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
.asset-picker__use {
    background-color: rgba(204, 204, 204, 0.9);
    border: none;
    cursor: pointer;
    font-size: 20px;
    height: 50px;
    left: calc(50% - 50px);
    padding-top: 4px;
    position: absolute;
    text-align: center;
    text-transform: uppercase;
    top: calc(50% - 25px);
    width: 100px;
    z-index: 20;
  }
.video-picker__video-label {
    display: block;
    font-family: 'DAZN Oscine', Helvetica, Arial, sans-serif;
    color: rgb(204, 204, 204);
    font-size: 1.6rem;
    padding: 1.6rem 0 0;
    margin: 0 0 1.6rem;
    text-align: left;
    text-transform: capitalize;
  }
.video-picker__video-sublabel {
    display: block;
    font-family: 'DAZN Oscine', Helvetica, Arial, sans-serif;
    color: rgb(204, 204, 204);
    font-size: 1.3rem;
    text-align: left;
    text-transform: capitalize;
  }
.video-picker__videos-list {
    margin-top: -3.5rem;
    max-height: 11rem;
    overflow-y: auto;
  }
.video-picker__videos-list li {
      cursor: pointer;
      display: block;
      list-style-type: none;
      text-align: left;
      padding: .2rem .5rem;
      font-size: 1.2rem;
    }
.video-picker__videos-list li:nth-child(even) {
        background: #666;
      }
.video-picker__videos-list li:nth-child(odd) {
        background: #444;
      }
.video-picker__videos-list li:hover:nth-child(even) {
          background: #777;
        }
.video-picker__videos-list li:hover:nth-child(odd) {
          background: #555;
        }
.video-picker__videos-list li a {
        float: right;
      }
.video-picker .asset-picker__list-item {
    float: left;
  }
.video-picker .asset-picker__list-item video {
      width: 100%;
    }
.video-picker .builder-modal__form-label--file-uploader {
    padding: 0;
    margin: 0;
  }
.file-uploader__clickable {
    cursor: pointer;
  }
