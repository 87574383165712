/* Expose global vars in root, no need to import these when used */
:root {
  /* colors */

  /* fonts */

  /* animation durations */

  /* Sizes */
}
/* breakpoints
  usage:
  @import path/to/settings.css;
  .className {
    @media (--bp2) {
      ...styles
    }
  }
*/
.icon-picker {
  max-width: 41.7rem;
  margin: 0 0 1.6rem;
  position: relative;
  z-index: 3;
  font-weight: bold;
  font-size: 2rem;
}
.icon-picker__button {
    min-width: 10rem;
    display: block;
    font-weight: bold;
    font-size: 2rem;
    font-family: 'DAZN Oscine', Helvetica, Arial, sans-serif;
    color: #ccc;
    outline: 0;
    border: 0;
    background: #484848;
    padding: 1.6rem;
    margin: 0;
    cursor: pointer;
    border-radius: .2rem;
    text-align: left;
    position: relative;
    z-index: 2;
    -webkit-transition: .2s ease all;
    transition: .2s ease all;
  }
.icon-picker__button::before {
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 6px 6px;
      border-color: transparent transparent #ccc transparent;
      position: absolute;
      right: 1.6rem;
      top: 1.7rem;
      -webkit-transition: .2s ease all;
      transition: .2s ease all;
    }
.icon-picker__button::after {
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 6px 6px 0;
      border-color: #ccc transparent transparent transparent;
      position: absolute;
      right: 1.6rem;
      top: 2.7rem;
      -webkit-transition: .2s ease all;
      transition: .2s ease all;
    }
.icon-picker__button--active,
    .icon-picker__button:active,
    .icon-picker__button:focus {
      color: white;
    }
.icon-picker__button--active::after, .icon-picker__button:active::after, .icon-picker__button:focus::after {
        border-color: white transparent transparent transparent !important;
      }
.icon-picker__button--active::before, .icon-picker__button:active::before, .icon-picker__button:focus::before {
        border-color: transparent transparent white transparent !important;
      }
.icon-picker__items {
    list-style-type: none;
    padding: 0;
    margin: 0;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: .2s ease all;
    transition: .2s ease all;
    position: absolute;
    top: 5.6rem;
    left: 0;
    background: #484848;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    line-height: 1.4;
  }
.icon-picker__items--visible {
      opacity: 1;
      visibility: visible;
      -webkit-box-shadow: 2px 2px 16px rgba(0, 0, 0, .3);
              box-shadow: 2px 2px 16px rgba(0, 0, 0, .3);
    }
.icon-picker__item-button {
      color: #ccc;
      background: #484848;
      border: 2px solid #555;
      padding: 1.2rem;
      margin: .2rem;
      cursor: pointer;
      border-radius: 0;
      font-size: 2rem;
    }
.icon-picker__item-button:hover .icon-picker__item-button__icon {
        color: white;
        -webkit-transform: scale(1.04);
                transform: scale(1.04);
      }
