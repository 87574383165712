/* Expose global vars in root, no need to import these when used */
:root {
  /* colors */

  /* fonts */

  /* animation durations */

  /* Sizes */
}
/* breakpoints
  usage:
  @import path/to/settings.css;
  .className {
    @media (--bp2) {
      ...styles
    }
  }
*/
.create-redirect-form {
  padding-bottom: 4.8rem;
}
.create-redirect-form__subtitle {
    font-family: 'DAZN Oscine', Helvetica, Arial, sans-serif;
    color: #ccc;
    font-size: 2.4rem;
    margin: 0 0 3.2rem;
  }
.create-redirect-form__field {
    display: block;
    min-width: 48rem;
    padding: 1.6rem;
    margin: 0 1.6rem 1.6rem 0;
    outline: 0;
    font-size: 1.6rem;
    border: 0;
    font-family: 'DAZN Oscine', Helvetica, Arial, sans-serif;
    color: white;
    border-bottom: 1px solid #484848;
    background: #484848;
    border-radius: .2rem;
    -webkit-transition: .3s ease all;
    transition: .3s ease all;
  }
