/* Expose global vars in root, no need to import these when used */
:root {
  /* colors */

  /* fonts */

  /* animation durations */

  /* Sizes */
}
/* breakpoints
  usage:
  @import path/to/settings.css;
  .className {
    @media (--bp2) {
      ...styles
    }
  }
*/
.redirects-table {
  padding-bottom: 4.8rem;
}
.redirects-table__table-action {
    font-size: 1.4rem !important;
    padding: .4rem 1.2rem !important;
    margin: 0 .4rem 0 0 !important;
  }
.redirects-table__subtitle {
    font-family: 'DAZN Oscine', Helvetica, Arial, sans-serif;
    color: #ccc;
    font-size: 2.4rem;
    margin: 0 0 3.2rem;
  }
