/* Expose global vars in root, no need to import these when used */
:root {
  /* colors */

  /* fonts */

  /* animation durations */

  /* Sizes */
}
/* breakpoints
  usage:
  @import path/to/settings.css;
  .className {
    @media (--bp2) {
      ...styles
    }
  }
*/
.archive-table {
  padding-bottom: 4.8rem;
}
.archive-table__table-action {
    font-size: 1.4rem !important;
    padding: .4rem 1.2rem !important;
    margin: 0 .4rem 0 0 !important;
  }
.archive-table__subtitle {
    font-family: 'DAZN Oscine', Helvetica, Arial, sans-serif;
    color: #ccc;
    font-size: 2.4rem;
    margin: 0 0 3.2rem;
  }
.archive-table__table {
    font-family: 'DAZN Oscine', Helvetica, Arial, sans-serif;
    font-size: 1.4rem;
    color: #ccc;
  }
.archive-table__table--column {
      text-align: center;
    }
.archive-table__table--column--name {
        text-align: left;
      }
.archive-table__table-filter-text {
      width: 100%;
      padding: 1.6rem;
      margin: 0 1.6rem 1.6rem 0;
      outline: 0;
      font-size: 1.6rem;
      border: 0;
      font-family: 'DAZN Oscine', Helvetica, Arial, sans-serif;
      color: white;
      background: #484848 !important;
      border-radius: .2rem;
    }
