/* Expose global vars in root, no need to import these when used */
:root {
  /* colors */

  /* fonts */

  /* animation durations */

  /* Sizes */
}
/* breakpoints
  usage:
  @import path/to/settings.css;
  .className {
    @media (--bp2) {
      ...styles
    }
  }
*/
.button {
  border: 1px solid white;
  border-radius: .2rem;
  background: transparent;
  -webkit-box-shadow: 1px 1px 1px rgba(0, 0, 0, .25);
          box-shadow: 1px 1px 1px rgba(0, 0, 0, .25);

  display: inline-block;

  outline: 0;
  padding: .8rem 3.2rem;
  margin: 0;

  font-size: 1.4rem;
  font-family: 'DAZN Oscine', Helvetica, Arial, sans-serif;
  font-weight: bold;
  text-decoration: none;
  text-transform: uppercase;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, .25);
  color: white;

  cursor: pointer;
  -webkit-transition: .3s ease all;
  transition: .3s ease all;
}
.button-icon {
    width: 1.2rem;
    height: 1.2rem;
    margin: 0 .4rem 0 0;
    vertical-align: middle;
    -webkit-filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, .25));
            filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, .25));
  }
.button-icon path {
      fill: white;
    }
.button-label {
    line-height: 1;
  }
.button--warning {
    background: #ea3757;
  }
.button--action {
    background: #f9b13b;
  }
.button--warning, .button--action {
    border-color: transparent;
  }
.button:disabled,
  .button--disabled {
    opacity: .5;
    cursor: not-allowed;
  }
.button--inline {
    margin: 0 .8rem 0 0 !important;
  }
.button--full-width {
    width: 100%;
  }
.button--tight-padding {
    padding: .8rem;
  }
.button--margin-right {
    margin-right: 1rem;
  }
.button > span {
    vertical-align: middle;
  }
.preview-page {
  background: black;
  min-height: 100vh;
}
.preview-page__spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
  }
.preview-page__spinner--hidden {
      display: none;
    }
.preview-page__frame {
    border: 0;
    display: block;
    -webkit-transition: all .2s ease-out;
    transition: all .2s ease-out;
    will-change: width, height;
  }
.preview-page__frame:not(.frame--mobile):not(.frame--desktop) {
      height: calc(100vh - 21rem);
    }
@media (min-width: 760px) {
.preview-page__frame:not(.frame--mobile):not(.frame--desktop) {
        height: calc(100vh - 18.2rem)
    }
      }
@media (min-width: 960px) {
.preview-page__frame:not(.frame--mobile):not(.frame--desktop) {
        height: calc(100vh - 15rem)
    }
      }
.preview-page__frame.frame--desktop {
      height: calc(100vh - 13.2rem);
    }
@media (min-width: 760px) {
.preview-page__frame.frame--desktop {
        height: calc(100vh - 9.2rem)
    }
      }
@media (min-width: 960px) {
.preview-page__frame.frame--desktop {
        height: calc(100vh - 5.2rem)
    }
      }
.preview-page__preview-pane {
    background: #333;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: .6rem .4rem;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    height: 13.2rem;
  }
.preview-page__preview-pane .button {
      padding-left: 1.2rem;
      padding-right: 1.2rem;
      margin: .2rem .8rem .2rem 0;
    }
.preview-page__preview-pane .pill {
      margin: 0 .2rem;
      overflow: hidden;
    }
@media (min-width: 760px) {
.preview-page__preview-pane {
      height: 9.2rem
  }
    }
@media (min-width: 960px) {
.preview-page__preview-pane {
      height: 5.2rem
  }
    }
.preview-page__preview-pills {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
.preview-page__preview-pills-label {
    font-family: 'DAZN Oscine', Helvetica, Arial, sans-serif;
    color: #ccc;
    font-weight: bold;
    display: inline-block;
    font-size: 1.4rem;
    margin: 0 1.2rem 0 2.4rem;
    text-transform: uppercase;
  }
.preview-page__preview-pills-icon {
    background-color: #484848;
  }
.preview-page__preview-pills-icon.preview-page__preview-pills-icon--mobile-ld.preview-page__preview-pills-icon--mobile-ld {
      -webkit-transform: rotate(-90deg);
              transform: rotate(-90deg);
      height: 3.6rem;
      width: 3.2rem;
    }
.preview-page__preview-pills-icon.preview-page__preview-pills-icon--tablet-ld.preview-page__preview-pills-icon--tablet-ld {
      -webkit-transform: rotate(-90deg);
              transform: rotate(-90deg);
      height: 3.6rem;
      width: 3.2rem;
    }
.preview-page__preview-pills-icon.preview-page__preview-pills-icon--tablet-ld.preview-page__preview-pills-icon--tablet-ld path {
        -webkit-transform: scaleX(1.5) scaleY(1.2) translate(-.5rem, -.2rem);
                transform: scaleX(1.5) scaleY(1.2) translate(-.5rem, -.2rem);
      }
.preview-page__preview-pills-icon.preview-page__preview-pills-icon--tablet.preview-page__preview-pills-icon--tablet {
      height: 3.2rem;
      width: 3.2rem;
    }
.preview-page__preview-pills-icon.preview-page__preview-pills-icon--tablet.preview-page__preview-pills-icon--tablet path {
        -webkit-transform: scaleX(1.5) scaleY(1.3) translate(-.5rem, -.4rem);
                transform: scaleX(1.5) scaleY(1.3) translate(-.5rem, -.4rem);
      }
.preview-page__frame-container {
    display: inline-block;
    border: 1.2rem solid #484848;
    border-bottom-width: 3.2rem;
    border-top-width: 3.2rem;
    border-radius: 1.2rem;
    margin: 1.6rem 0 0 0;
    will-change: border;
    -webkit-transition: all .2s linear;
    transition: all .2s linear;
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
  }
.preview-page__frame-container.preview-iframe--ld {
      border-bottom-width: 1.2rem;
      border-top-width: 1.2rem;
      border-left-width: 3.2rem;
      border-right-width: 3.2rem;
    }
.preview-page__frame-container.preview-iframe--desktop.preview-iframe--desktop {
      border: 0;
      margin: 0;
      display: block;
      width: 100%;
      border-radius: 0;
    }
.preview-page__controls {
    width: 100%;
  }
.preview-page__controls .button {
      display: block;
      margin-bottom: 1rem !important;
    }
.preview-page__controls .button:last-child {
        margin-bottom: 0 !important;
      }
@media (min-width: 760px) {
      .preview-page__controls .button {
        display: inline-block;
        margin-bottom: initial !important;
      }
    }
.preview-page__frame-box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
#preview-frame {
  background: black;
}
