/* Expose global vars in root, no need to import these when used */
:root {
  /* colors */

  /* fonts */

  /* animation durations */

  /* Sizes */
}
/* breakpoints
  usage:
  @import path/to/settings.css;
  .className {
    @media (--bp2) {
      ...styles
    }
  }
*/
.history-diff-modal {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  height: 100%;
}
.history-diff-modal .show-all-checkbox {
  color: white;
  line-height: 2.6rem;
  padding-left: 0.5rem;
  font-size: 1.4rem;
  font-family: 'DAZN Oscine', Helvetica, Arial, sans-serif;
  margin-bottom: 1.6rem;
}
.history-diff-modal .history-diff-code {
  color: black;
  background: white;
  padding: 24px;
  overflow: auto;
  position: absolute;
  top: 74px;
  left: 0;
  right: 0;
  bottom: 0;
}
.jsondiffpatch-delta {
  font-family: 'DAZN Oscine', Helvetica, Arial, sans-serif;
  font-size: 1.4rem;
  margin: 0;
  padding: 0 0 0 1rem;
  display: inline-block;
}
.jsondiffpatch-delta pre {
  font-family: 'DAZN Oscine', Helvetica, Arial, sans-serif;
  font-size: 1.4rem;
  margin: 0;
  padding: 0;
  display: inline-block;
}
ul.jsondiffpatch-delta {
  list-style-type: none;
  padding: 0 0 0 1.66rem;
  margin: 0;
}
.jsondiffpatch-delta ul {
  list-style-type: none;
  padding: 0 0 0 1.66rem;
  margin: 0;
}
.jsondiffpatch-property-name {
  display: inline-block;
  padding-right: 0.5rem;
  vertical-align: top;
}
.jsondiffpatch-deleted pre,
.jsondiffpatch-deleted .jsondiffpatch-property-name,
.jsondiffpatch-modified .jsondiffpatch-left-value pre,
.jsondiffpatch-textdiff-deleted {
  background: #fbb;
  text-decoration: line-through;
}
.jsondiffpatch-added .jsondiffpatch-property-name,
.jsondiffpatch-added .jsondiffpatch-value pre,
.jsondiffpatch-modified .jsondiffpatch-right-value pre,
.jsondiffpatch-textdiff-added {
  background: #bfb;
}
.jsondiffpatch-value {
  display: inline-block;
}
.jsondiffpatch-moved .jsondiffpatch-value {
  display: none;
}
.jsondiffpatch-unchanged,
.jsondiffpatch-movedestination {
  color: grey;
}
.jsondiffpatch-unchanged {
  display: none;
}
.jsondiffpatch-modified .jsondiffpatch-value {
  display: inline-block;
}
.jsondiffpatch-unchanged,
.jsondiffpatch-movedestination > .jsondiffpatch-value {
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  overflow-y: hidden;
}
.jsondiffpatch-unchanged-showing .jsondiffpatch-unchanged,
.jsondiffpatch-unchanged-showing .jsondiffpatch-movedestination > .jsondiffpatch-value {
  max-height: 10rem;
}
.jsondiffpatch-unchanged-hidden .jsondiffpatch-unchanged,
.jsondiffpatch-unchanged-hidden .jsondiffpatch-movedestination > .jsondiffpatch-value {
  max-height: 0;
}
.jsondiffpatch-unchanged-hiding .jsondiffpatch-movedestination > .jsondiffpatch-value,
.jsondiffpatch-unchanged-hidden .jsondiffpatch-movedestination > .jsondiffpatch-value {
  display: block;
}
.jsondiffpatch-unchanged-visible .jsondiffpatch-unchanged,
.jsondiffpatch-unchanged-visible .jsondiffpatch-movedestination > .jsondiffpatch-value {
  max-height: 10rem;
}
.jsondiffpatch-unchanged-hiding .jsondiffpatch-unchanged,
.jsondiffpatch-unchanged-hiding .jsondiffpatch-movedestination > .jsondiffpatch-value {
  max-height: 0;
}
.jsondiffpatch-unchanged-showing .jsondiffpatch-arrow,
.jsondiffpatch-unchanged-hiding .jsondiffpatch-arrow {
  display: none;
}
.jsondiffpatch-property-name::after {
  content: ': ';
}
.jsondiffpatch-child-node-type-array > .jsondiffpatch-property-name::after {
  content: ': [';
}
.jsondiffpatch-child-node-type-array::after {
  content: '],';
}
div.jsondiffpatch-child-node-type-array::before {
  content: '[';
}
div.jsondiffpatch-child-node-type-array::after {
  content: ']';
}
.jsondiffpatch-child-node-type-object > .jsondiffpatch-property-name::after {
  content: ': {';
}
.jsondiffpatch-child-node-type-object::after {
  content: '},';
}
div.jsondiffpatch-child-node-type-object::before {
  content: '{';
}
div.jsondiffpatch-child-node-type-object::after {
  content: '}';
}
.jsondiffpatch-value pre::after {
  content: ',';
}
.jsondiffpatch-modified > .jsondiffpatch-left-value pre::after,
li:last-child > .jsondiffpatch-value pre::after {
  content: '';
}
.jsondiffpatch-modified .jsondiffpatch-right-value {
  margin-left: 0.5rem;
}
.jsondiffpatch-moved .jsondiffpatch-moved-destination {
  display: inline-block;
  background: #ffb;
  color: #888;
}
.jsondiffpatch-moved .jsondiffpatch-moved-destination::before {
  content: ' => ';
}
ul.jsondiffpatch-textdiff {
  padding: 0;
}
.jsondiffpatch-textdiff-location {
  color: #bbb;
  display: inline-block;
  min-width: 5rem;
}
.jsondiffpatch-textdiff-line {
  display: inline-block;
}
.jsondiffpatch-textdiff-line-number::after {
  content: ',';
}
.jsondiffpatch-error {
  background: red;
  color: white;
  font-weight: bold;
}
.jsondiffpatch-unchanged[data-key='component'],
.jsondiffpatch-unchanged[data-key='components'],
.jsondiffpatch-unchanged[data-key='metaTags'] {
  display: block;
}
.show-unmodified .jsondiffpatch-unchanged {
  display: block;
}