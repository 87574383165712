/* Expose global vars in root, no need to import these when used */
:root {
  /* colors */

  /* fonts */

  /* animation durations */

  /* Sizes */
}
/* breakpoints
  usage:
  @import path/to/settings.css;
  .className {
    @media (--bp2) {
      ...styles
    }
  }
*/
.defaults-page__general {
    padding: 2.4rem 3.2rem 4.8rem;
    margin: 3rem 0 2rem;
  }
