/* Expose global vars in root, no need to import these when used */
:root {
  /* colors */

  /* fonts */

  /* animation durations */

  /* Sizes */
}
/* breakpoints
  usage:
  @import path/to/settings.css;
  .className {
    @media (--bp2) {
      ...styles
    }
  }
*/
.button {
  border: 1px solid white;
  border-radius: .2rem;
  background: transparent;
  -webkit-box-shadow: 1px 1px 1px rgba(0, 0, 0, .25);
          box-shadow: 1px 1px 1px rgba(0, 0, 0, .25);

  display: inline-block;

  outline: 0;
  padding: .8rem 3.2rem;
  margin: 0;

  font-size: 1.4rem;
  font-family: 'DAZN Oscine', Helvetica, Arial, sans-serif;
  font-weight: bold;
  text-decoration: none;
  text-transform: uppercase;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, .25);
  color: white;

  cursor: pointer;
  -webkit-transition: .3s ease all;
  transition: .3s ease all;
}
.button-icon {
    width: 1.2rem;
    height: 1.2rem;
    margin: 0 .4rem 0 0;
    vertical-align: middle;
    -webkit-filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, .25));
            filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, .25));
  }
.button-icon path {
      fill: white;
    }
.button-label {
    line-height: 1;
  }
.button--warning {
    background: #ea3757;
  }
.button--action {
    background: #f9b13b;
  }
.button--warning, .button--action {
    border-color: transparent;
  }
.button:disabled,
  .button--disabled {
    opacity: .5;
    cursor: not-allowed;
  }
.button--inline {
    margin: 0 .8rem 0 0 !important;
  }
.button--full-width {
    width: 100%;
  }
.button--tight-padding {
    padding: .8rem;
  }
.button--margin-right {
    margin-right: 1rem;
  }
.button > span {
    vertical-align: middle;
  }
.reports-table__general p {
      font-size: 1.4rem;
      margin: 0;
      padding: 0 2rem;
      color: #ccc;
      display: inline-block;
    }
.reports-table__general p:first-child {
        padding-left: 0;
      }
.reports-table__general p:last-child {
        padding-right: 0;
      }
.reports-table__general p span {
        color: white;
        font-weight: bold;
      }
.reports-table__general p.reports-table__general-green span {
          color: #26a283;
        }
.reports-table__general p.reports-table__general-red span {
          color: #ea3757;
        }
.reports-table__table-action {
    font-size: 1.4rem !important;
    padding: .4rem 1.2rem !important;
    margin: 0 .4rem 0 0 !important;
  }
.reports-table__site-table {
    background: none;
    font-family: 'DAZN Oscine', Helvetica, Arial, sans-serif;
    font-size: 1.4rem;
    color: #ccc;
    margin: 3.6rem 0 0;
    border: 0 !important;
  }
.reports-table__site-table .rt-noData {
      background: #333 !important;
      color: #ccc !important;
      font-size: 1.6rem;
      font-family: 'DAZN Oscine', Helvetica, Arial, sans-serif;
    }
.reports-table__site-table__filter-text {
      width: 100%;
      padding: 1.6rem;
      margin: 0 1.6rem 1.6rem 0;
      outline: 0;
      font-size: 1.6rem;
      border: 0;
      font-family: 'DAZN Oscine', Helvetica, Arial, sans-serif;
      color: white;
      background: #484848 !important;
      border-radius: .2rem;
    }
.reports-table__site-table .-pagination .-btn {
        color: #ccc !important;
      }
.reports-table__site-table a {
      color: #ccc;
      text-decoration: none;
      padding-bottom: .1rem;
      -webkit-transition: .2s ease all;
      transition: .2s ease all;
    }
/* border-bottom: 1px solid var(--color-grey-light); */
.reports-table__site-table a:hover,
      .reports-table__site-table a:focus,
      .reports-table__site-table a:active {
        color: white;
        border-bottom: 1px solid white;
      }
.reports-table__site-table.-striped .rt-tr.-odd {
          background: #484848 !important;
          border-radius: .2rem;
        }
.reports-table__site-table__column-header--left {
      text-align: left;
    }
.reports-table__site-table--column {
      text-align: center;
    }
.reports-table__site-table--column--name {
        text-align: left;
      }
.reports-table__site-table--column--status {
        color: white;
      }
.reports-table__site-table--column--status.status--ok {
          background: #26a283;
        }
.reports-table__site-table--column--status.status--not-ok {
          background: #ea3757;
        }
.reports-table__site-table .rt-tbody .rt-td {
        -ms-flex-item-align: center;
            align-self: center;
        padding: 1.6rem .8rem;
        border-right: 1px solid #393939;
      }
.reports-table__site-table .rt-tbody .rt-tr-group {
        border-bottom: 1px solid #484848 !important;
      }
.reports-table__site-table .rt-thead {
      background: none;
      -webkit-box-shadow: initial !important;
              box-shadow: initial !important;
    }
.reports-table__site-table .rt-thead .rt-tr {
        font-family: 'DAZN Oscine', Helvetica, Arial, sans-serif;
      }
.reports-table__site-table .rt-thead .rt-tr .rt-th {
          padding: 1.6rem .8rem;
          border-right: 1px solid #393939;
        }
.reports-table__site-table .rt-thead .rt-tr .rt-th.-sort-desc {
            -webkit-box-shadow: inset 0 -2px 0 0 #ccc;
                    box-shadow: inset 0 -2px 0 0 #ccc;
          }
.reports-table__site-table .rt-thead .rt-tr .rt-th.-sort-asc {
            -webkit-box-shadow: inset 0 2px 0 0 #ccc;
                    box-shadow: inset 0 2px 0 0 #ccc;
          }
