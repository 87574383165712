/* Expose global vars in root, no need to import these when used */
:root {
  /* colors */

  /* fonts */

  /* animation durations */

  /* Sizes */
}
/* breakpoints
  usage:
  @import path/to/settings.css;
  .className {
    @media (--bp2) {
      ...styles
    }
  }
*/
.button {
  border: 1px solid white;
  border-radius: .2rem;
  background: transparent;
  -webkit-box-shadow: 1px 1px 1px rgba(0, 0, 0, .25);
          box-shadow: 1px 1px 1px rgba(0, 0, 0, .25);

  display: inline-block;

  outline: 0;
  padding: .8rem 3.2rem;
  margin: 0;

  font-size: 1.4rem;
  font-family: 'DAZN Oscine', Helvetica, Arial, sans-serif;
  font-weight: bold;
  text-decoration: none;
  text-transform: uppercase;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, .25);
  color: white;

  cursor: pointer;
  -webkit-transition: .3s ease all;
  transition: .3s ease all;
}
.button-icon {
    width: 1.2rem;
    height: 1.2rem;
    margin: 0 .4rem 0 0;
    vertical-align: middle;
    -webkit-filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, .25));
            filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, .25));
  }
.button-icon path {
      fill: white;
    }
.button-label {
    line-height: 1;
  }
.button--warning {
    background: #ea3757;
  }
.button--action {
    background: #f9b13b;
  }
.button--warning, .button--action {
    border-color: transparent;
  }
.button:disabled,
  .button--disabled {
    opacity: .5;
    cursor: not-allowed;
  }
.button--inline {
    margin: 0 .8rem 0 0 !important;
  }
.button--full-width {
    width: 100%;
  }
.button--tight-padding {
    padding: .8rem;
  }
.button--margin-right {
    margin-right: 1rem;
  }
.button > span {
    vertical-align: middle;
  }
.users-page__subtitle {
    font-family: 'DAZN Oscine', Helvetica, Arial, sans-serif;
    color: #ccc;
    font-size: 3.6rem;
    margin: 0 0 3.2rem;
  }
.users-page__territory {
    font-family: 'DAZN Oscine', Helvetica, Arial, sans-serif;
    color: #ccc;
    font-size: 1.6rem;
    padding: 0;
    text-transform: capitalize;
  }
.users-page__territory--upper {
      text-transform: uppercase;
    }
.users-page__label {
    position: relative;
    font-family: 'DAZN Oscine', Helvetica, Arial, sans-serif;
    color: #ccc;
    font-size: 1.6rem;
    margin: 0 0 1.6rem;
    padding: 0;
  }
.users-page__label--upper {
      text-transform: uppercase;
    }
.users-page__separator {
    margin: 3.2rem 0;
    height: 0;
    width: 48rem;
    border: 0;
    border-bottom: .1rem solid #484848;
  }
.users-page__password-button {
    width: 23rem;
  }
.users-page__button-wrapper {
    width: 48rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    margin-bottom: 1.6rem;
  }
.users-page__user-enabled {
    width: 2rem;
    height: 2rem;
  }
.users-page__user-enabled path {
      fill: #26a283;
    }
.users-page__user-enabled--disabled path {
        fill: #ea3757;
      }
.users-page__list__langs {
      margin: 0 0 3.2rem;
    }
.users-page__list__button {
      display: block;
      outline: 0;
      border: 0;
      padding: .8rem 6.4rem;
      color: white;
      background: #f9b13b;
      font-size: 1.6rem;
      font-family: 'DAZN Oscine', Helvetica, Arial, sans-serif;
      cursor: pointer;
      -webkit-transition: .3s ease all;
      transition: .3s ease all;
      border-radius: .2rem;
    }
.users-page__list__button:disabled {
        opacity: .5;
        cursor: not-allowed;
      }
.users-page__table-action {
    font-size: 1.4rem !important;
    padding: .4rem 1.2rem !important;
    margin: 0 .4rem 0 0 !important;
  }
.users-page__create {
    padding-bottom: 4.8rem;
    position: relative;
    z-index: 99;
  }
.users-page__create__langs {
      margin: 0 0 3.2rem;
    }
.users-page__create__button {
      display: block;
      outline: 0;
      border: 0;
      padding: .8rem 6.4rem;
      color: white;
      background: #f9b13b;
      font-size: 1.6rem;
      font-family: 'DAZN Oscine', Helvetica, Arial, sans-serif;
      cursor: pointer;
      -webkit-transition: .3s ease all;
      transition: .3s ease all;
      border-radius: .2rem;
    }
.users-page__create__button:disabled {
        opacity: .5;
        cursor: not-allowed;
      }
.users-page__form-field {
    display: block;
    min-width: 48rem;
    padding: 1.2rem 1.6rem;
    margin: 0 1.6rem 1.6rem 0;
    outline: 0;
    border: 0;
    
    font-size: 1.6rem;
    font-family: 'DAZN Oscine', Helvetica, Arial, sans-serif;
    color: white;
    border-bottom: 1px solid #484848;
    background: #484848;
    border-radius: .2rem;
    -webkit-transition: .3s ease all;
    transition: .3s ease all;
  }
.users-page__form-field--error {
      border-bottom: 1px solid #ea3757;
    }
.users-page__table-link {
    cursor: pointer;
  }
