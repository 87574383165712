/* Expose global vars in root, no need to import these when used */
:root {
  /* colors */

  /* fonts */

  /* animation durations */

  /* Sizes */
}
/* breakpoints
  usage:
  @import path/to/settings.css;
  .className {
    @media (--bp2) {
      ...styles
    }
  }
*/
.create-page-form {
  padding-bottom: 4.8rem;
  z-index: 2;
  position: relative;
}
.create-page-form__subtitle {
    font-family: 'DAZN Oscine', Helvetica, Arial, sans-serif;
    color: #ccc;
    font-size: 2.4rem;
    margin: 0 0 3.2rem;
  }
.create-page-form__create__form {
    z-index: 1;
  }
.create-page-form__field {
    display: block;
    min-width: 48rem;
    padding: 1.2rem 1.6rem;
    margin: 0 1.6rem 1.6rem 0;
    outline: 0;
    background: #484848;

    font-size: 1.6rem;
    font-family: 'DAZN Oscine', Helvetica, Arial, sans-serif;
    color: white;

    border: 0;
    border-bottom: 1px solid #484848;
    border-radius: .2rem;

    -webkit-transition: .3s ease all;

    transition: .3s ease all;
  }
.create-page-form__field--error {
      border-bottom: 1px solid #ea3757;
    }
