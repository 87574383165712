/* Expose global vars in root, no need to import these when used */
:root {
  /* colors */

  /* fonts */

  /* animation durations */

  /* Sizes */
}
/* breakpoints
  usage:
  @import path/to/settings.css;
  .className {
    @media (--bp2) {
      ...styles
    }
  }
*/
.button {
  border: 1px solid white;
  border-radius: .2rem;
  background: transparent;
  -webkit-box-shadow: 1px 1px 1px rgba(0, 0, 0, .25);
          box-shadow: 1px 1px 1px rgba(0, 0, 0, .25);

  display: inline-block;

  outline: 0;
  padding: .8rem 3.2rem;
  margin: 0;

  font-size: 1.4rem;
  font-family: 'DAZN Oscine', Helvetica, Arial, sans-serif;
  font-weight: bold;
  text-decoration: none;
  text-transform: uppercase;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, .25);
  color: white;

  cursor: pointer;
  -webkit-transition: .3s ease all;
  transition: .3s ease all;
}
.button-icon {
    width: 1.2rem;
    height: 1.2rem;
    margin: 0 .4rem 0 0;
    vertical-align: middle;
    -webkit-filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, .25));
            filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, .25));
  }
.button-icon path {
      fill: white;
    }
.button-label {
    line-height: 1;
  }
.button--warning {
    background: #ea3757;
  }
.button--action {
    background: #f9b13b;
  }
.button--warning, .button--action {
    border-color: transparent;
  }
.button:disabled,
  .button--disabled {
    opacity: .5;
    cursor: not-allowed;
  }
.button--inline {
    margin: 0 .8rem 0 0 !important;
  }
.button--full-width {
    width: 100%;
  }
.button--tight-padding {
    padding: .8rem;
  }
.button--margin-right {
    margin-right: 1rem;
  }
.button > span {
    vertical-align: middle;
  }
.extended-react-table {
  background: none;
  font-family: 'DAZN Oscine', Helvetica, Arial, sans-serif;
  font-size: 1.4rem;
  color: #ccc;
  margin: 3.6rem 0 0;
  border: 0 !important;
}
.extended-react-table.ReactTable .-loading {
      background: transparent;
      text-align: center;
    }
.extended-react-table.ReactTable .-loading-inner.-loading-inner {
      display: inline-block;
      text-align: center;
      width: auto;
      top: 50%;
      left: 50%;
      margin-left: -4.5rem;
      padding: .8rem 1rem;
      background: rgba(255, 255, 255, .8);
    }
.extended-react-table .rt-noData {
    background: #333 !important;
    color: #ccc !important;
    font-size: 1.6rem;
    font-family: 'DAZN Oscine', Helvetica, Arial, sans-serif;
    opacity: 0.85;
  }
.extended-react-table__filter-text {
    width: 100%;
    padding: 1.6rem;
    margin: 0 1.6rem 1.6rem 0;
    outline: 0;
    font-size: 1.6rem;
    border: 0;
    font-family: 'DAZN Oscine', Helvetica, Arial, sans-serif;
    color: white;
    background: #484848 !important;
    border-radius: 0.2rem;
  }
.extended-react-table .-pagination .-btn {
      color: #ccc !important;
    }
.extended-react-table a {
    color: #ccc;
    text-decoration: none;
    padding-bottom: .1rem;
    -webkit-transition: .2s ease all;
    transition: .2s ease all;
  }
.extended-react-table a:hover,
    .extended-react-table a:focus,
    .extended-react-table a:active {
      color: white;
      border-bottom: 1px solid white;
    }
.extended-react-table a.button {
    border: none;
    color: white;
  }
.extended-react-table.-striped .rt-tr.-odd {
        background: #484848 !important;
        border-radius: .2rem;
      }
.extended-react-table__column-header--left {
    text-align: left;
  }
.extended-react-table--column {
    text-align: center;
  }
.extended-react-table--column--name {
      text-align: left;
    }
.extended-react-table--column--status {
      color: white;
    }
.extended-react-table .rt-tbody .rt-td {
      -ms-flex-item-align: center;
          align-self: center;
      padding: 1.6rem .8rem;
      border-right: 1px solid #393939;
      overflow-x: auto;
    }
.extended-react-table .rt-tbody .rt-tr-group {
      border-bottom: 1px solid #484848 !important;
    }
.extended-react-table .rt-thead {
    background: none;
    -webkit-box-shadow: initial !important;
            box-shadow: initial !important;
  }
.extended-react-table .rt-thead .rt-tr {
      font-family: 'DAZN Oscine', Helvetica, Arial, sans-serif;
    }
.extended-react-table .rt-thead .rt-tr .rt-th {
        padding: 1.6rem .8rem;
        border-right: 1px solid #393939;
      }
.extended-react-table .rt-thead .rt-tr .rt-th.-sort-desc {
          -webkit-box-shadow: inset 0 -2px 0 0 #ccc;
                  box-shadow: inset 0 -2px 0 0 #ccc;
        }
.extended-react-table .rt-thead .rt-tr .rt-th.-sort-asc {
          -webkit-box-shadow: inset 0 2px 0 0 #ccc;
                  box-shadow: inset 0 2px 0 0 #ccc;
        }
.extended-react-table .rt-thead .rt-tr .rt-th select {
          width: 100%;
          font-size: 1.4rem;
          border: 0;
          font-family: 'DAZN Oscine', Helvetica, Arial, sans-serif;
          color: white;
          background: #484848;
          height: 3rem;
        }
.extended-react-table .rt-thead .rt-tr .rt-th select > option {
            margin-top: .1rem;
            background: #484848;
            width: 22.6rem;
            height: 4.8rem;
            padding: 1.6rem;
          }
.extended-react-table__site-table--column {
      text-align: center;
    }
.extended-react-table__site-table--column--capitalize {
        text-transform: capitalize;
      }
.extended-react-table__site-table--column--name {
        text-align: left;
      }
.extended-react-table__site-table--column--status {
        color: white;
      }
.extended-react-table__site-table--column--left {
        text-align: left;
      }
.extended-react-table__site-table--column--link {
        text-decoration: underline !important;
      }
