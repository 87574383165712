/* Expose global vars in root, no need to import these when used */
:root {
  /* colors */

  /* fonts */

  /* animation durations */

  /* Sizes */
}
/* breakpoints
  usage:
  @import path/to/settings.css;
  .className {
    @media (--bp2) {
      ...styles
    }
  }
*/
.pages-table {
  padding-bottom: 4.8rem;
  z-index: 1;
  position: relative;
}
.pages-table__table-action {
    font-size: 1.4rem !important;
    padding: .4rem 1.2rem !important;
    margin: 0 .4rem 0 0 !important;
  }
.pages-table__subtitle {
    font-family: 'DAZN Oscine', Helvetica, Arial, sans-serif;
    color: #ccc;
    font-size: 2.4rem;
    margin: 0 0 3.2rem;
  }
.pages-table__table {
    font-family: 'DAZN Oscine', Helvetica, Arial, sans-serif;
    font-size: 1.4rem;
    color: #ccc;
  }
.pages-table__table--column {
      text-align: center;
    }
.pages-table__table--column--name {
        text-align: left;
      }
.pages-table__table--column--status {
        color: white;
      }
.pages-table__table-filter-text {
      width: 100%;
      padding: 1.6rem;
      margin: 0 1.6rem 1.6rem 0;
      outline: 0;
      font-size: 1.6rem;
      border: 0;
      font-family: 'DAZN Oscine', Helvetica, Arial, sans-serif;
      color: white;
      background: #484848 !important;
      border-radius: .2rem;
    }
.pages-table__table__status {
      display: inline-block;
      width: .8rem;
      height: .8rem;
      margin: 0 .6rem 0 0;
      background: none;
      border-radius: 50%;
      vertical-align: middle;
    }
.pages-table__table__status--live {
        background: #0c0;
      }
.pages-table__table__status--failed {
        background: #ea3757;
      }
.pages-table__table__status--deploying {
        border-top: 2px solid #3bacf7;
        border-right: 2px solid #3bacf7;
        border-left: 2px solid #3bacf7;
        border-bottom: 2px solid transparent;
        -webkit-animation: spin .7s linear infinite;
                animation: spin .7s linear infinite;
        width: 1rem;
        height: 1rem;
      }
.pages-table__table--page-state {
      text-transform: capitalize;
    }
.pages-table__table--link {
      color: #ccc;
      text-decoration: none;
      padding-bottom: .1rem;
      border-bottom: 1px solid #ccc;
      -webkit-transition: .2s ease all;
      transition: .2s ease all;
    }
.pages-table__table--link:hover,
      .pages-table__table--link:focus,
      .pages-table__table--link:active {
        color: white;
        border-bottom: 1px solid white;
      }
