/* Expose global vars in root, no need to import these when used */
:root {
  /* colors */

  /* fonts */

  /* animation durations */

  /* Sizes */
}
/* breakpoints
  usage:
  @import path/to/settings.css;
  .className {
    @media (--bp2) {
      ...styles
    }
  }
*/
.builder-notification {
  font-size: 1.5rem;
}
.builder-notification-content {
    font-family: 'DAZN Oscine', Helvetica, Arial, sans-serif !important;
  }
.builder-notification-warning {
    background-color: #f9b13b;
  }
.builder-notification-error {
    background-color: #ea3757;
  }
.builder-notification-success {
    background-color: #26a283;
  }
.builder-notification-info {
    background-color: #969696;
  }
