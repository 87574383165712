/* Expose global vars in root, no need to import these when used */
:root {
  /* colors */

  /* fonts */

  /* animation durations */

  /* Sizes */
}
/* breakpoints
  usage:
  @import path/to/settings.css;
  .className {
    @media (--bp2) {
      ...styles
    }
  }
*/
.button {
  border: 1px solid white;
  border-radius: .2rem;
  background: transparent;
  -webkit-box-shadow: 1px 1px 1px rgba(0, 0, 0, .25);
          box-shadow: 1px 1px 1px rgba(0, 0, 0, .25);

  display: inline-block;

  outline: 0;
  padding: .8rem 3.2rem;
  margin: 0;

  font-size: 1.4rem;
  font-family: 'DAZN Oscine', Helvetica, Arial, sans-serif;
  font-weight: bold;
  text-decoration: none;
  text-transform: uppercase;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, .25);
  color: white;

  cursor: pointer;
  -webkit-transition: .3s ease all;
  transition: .3s ease all;
}
.button-icon {
    width: 1.2rem;
    height: 1.2rem;
    margin: 0 .4rem 0 0;
    vertical-align: middle;
    -webkit-filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, .25));
            filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, .25));
  }
.button-icon path {
      fill: white;
    }
.button-label {
    line-height: 1;
  }
.button--warning {
    background: #ea3757;
  }
.button--action {
    background: #f9b13b;
  }
.button--warning, .button--action {
    border-color: transparent;
  }
.button:disabled,
  .button--disabled {
    opacity: .5;
    cursor: not-allowed;
  }
.button--inline {
    margin: 0 .8rem 0 0 !important;
  }
.button--full-width {
    width: 100%;
  }
.button--tight-padding {
    padding: .8rem;
  }
.button--margin-right {
    margin-right: 1rem;
  }
.button > span {
    vertical-align: middle;
  }
.builder-page {
  background: black;
  margin: -4rem -3.2rem -4.8rem;
}
.builder-page__meta {
    width: 75%;
    margin: 0 auto;
  }
.builder-page__button {
    display: block;
    outline: 0;
    border: 0;
    padding: .8rem 6.4rem;
    color: white;
    background: #f9b13b;
    font-size: 1.6rem;
    font-family: 'DAZN Oscine', Helvetica, Arial, sans-serif;
    cursor: pointer;
    -webkit-transition: .3s ease all;
    transition: .3s ease all;
    border-radius: .2rem;
  }
.builder-page__button:disabled {
      opacity: .5;
      cursor: not-allowed;
    }
.builder-page__button--inline {
      display: inline-block;
    }
.builder-page__button--warning {
      background-color: #ea3757;
      color: white;
    }
.builder-page__controls {
    background-color: rgba(255, 255, 255, 0.4);
    font-size: 1.8rem;
    margin: .3rem .8rem .8rem .8rem;
    padding: .8rem;
  }
.builder-page__spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -2.9rem;
    margin-left: -2.9rem;
  }
