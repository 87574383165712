/* Expose global vars in root, no need to import these when used */
:root {
  /* colors */

  /* fonts */

  /* animation durations */

  /* Sizes */
}
/* breakpoints
  usage:
  @import path/to/settings.css;
  .className {
    @media (--bp2) {
      ...styles
    }
  }
*/
.component-defaults__navigation {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    background-color: #2b2b2b;
    padding-left: 1rem;
    font-family: 'DAZN Oscine', Helvetica, Arial, sans-serif;
    font-size: 1.6rem;
    color: #ccc;
    margin-bottom: .4rem;
  }
.component-defaults__label {
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    cursor: default;
    padding: 1rem 0;
  }
.component-defaults__edit {
    margin: .8rem;
    font-size: 1.2rem;
    padding: .4rem 1.6rem;
  }
.component-defaults__sandbox {
    background: black;
  }
